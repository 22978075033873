import Cookies from 'js-cookie';

const STORAGE_TYPE = {
  Cookies: 'cookies',
  LocalStorage: 'local-storage',
  SessionStorage: 'session-storage',
};

Object.freeze(STORAGE_TYPE);

export const getItem = ({ key, storage = STORAGE_TYPE.Cookies }) => {
  switch (storage) {
    case STORAGE_TYPE.Cookies:
      return Cookies.getJSON(key);
    case STORAGE_TYPE.LocalStorage:
      return localStorage.getItem(key);
    case STORAGE_TYPE.SessionStorage:
      return sessionStorage.getItem(key);
    default:
      break;
  }
};

export const setItem = ({
  key,
  value,
  storage = STORAGE_TYPE.Cookies,
  expiresInDays = null,
}) => {
  switch (storage) {
    case STORAGE_TYPE.Cookies:
      if (expiresInDays)
        return Cookies.set(key, value, { expires: expiresInDays });
      return Cookies.set(key, value);
    case STORAGE_TYPE.LocalStorage:
      return localStorage.setItem(key, value);
    case STORAGE_TYPE.SessionStorage:
      return sessionStorage.setItem(key, value);
    default:
      break;
  }
};

export const removeItem = ({ key, storage = STORAGE_TYPE.Cookies }) => {
  switch (storage) {
    case STORAGE_TYPE.Cookies:
      return Cookies.remove(key);
    case STORAGE_TYPE.LocalStorage:
      return localStorage.removeItem(key);
    case STORAGE_TYPE.SessionStorage:
      return sessionStorage.removeItem(key);
    default:
      break;
  }
};

const storage = { STORAGE_TYPE, getItem, setItem, removeItem };

export default storage;
