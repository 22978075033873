import { useLocation } from '@reach/router';
import React, { useState, useContext, useEffect } from 'react';

import getParamByName from 'utilities/getParamByName';
import { getToken, setToken, removeToken } from 'utilities/storage/authStorage';

import api from 'api/app';

const AuthUserContext = React.createContext({
  isLoggedIn: false,
  user: null,
  loading: true,
  login: () => {},
  logout: () => {},
  resetUser: () => {},
  updateUser: () => {},
});

export const AuthUserProvider = ({ children }) => {
  const { search } = useLocation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    const token = getParamByName('code', search);
    if (token) {
      setToken({ token });
    }
  }, [search]);

  useEffect(() => {
    async function loadUserFromParamOrCookies() {
      try {
        const token = getToken();
        if (token) {
          const { data: user } = await api.get('/users/me');
          if (user) setUser(user);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
    loadUserFromParamOrCookies();
  }, []);

  const updateUser = async () => {
    try {
      const { data: user } = await api.get('/users/me');
      if (user) setUser(user);
    } catch {}
  };

  const resetUser = ({ data }) => {
    if (!data) {
      return;
    }
    if (data.jwt) {
      setToken({ token: data.jwt });
    }
    if (data.user) setUser(data.user);
  };

  const login = async ({ email, password }) => {
    setLoading(true);
    try {
      const { data } = await api.post('/auth/local', {
        email,
        password,
      });
      setLoading(false);
      if (data) {
        setToken({ token: data.jwt });
        const { data: user } = await api.get('/users/me');
        if (user) setUser(user);
      }
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const logout = () => {
    removeToken();
    setUser(null);
  };

  return (
    <AuthUserContext.Provider
      value={{
        isLoggedIn: !!user,
        user,
        login,
        loading,
        updateUser,
        resetUser,
        logout,
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

export const useAuthUser = () => {
  return useContext(AuthUserContext);
};
