module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://minoera.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":""},"googleTagManager":{"trackingId":"GTM-WVST4D3","cookieName":"gdpr-acceptance"},"facebookPixel":{"pixelId":"1240756363042920","cookieName":"gdpr-acceptance"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Minoera Traditional Cretan Jewelry Store","short_name":"Minoera","start_url":"/","background_color":"#000","theme_color":"#fff","display":"minimal-ui","icon":"src/assets/icons/minotaur_only.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6e5b2318a0574757b1bc96cf883fe4fd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#c5a557"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
