// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anazitisi-jsx": () => import("./../../../src/pages/anazitisi.jsx" /* webpackChunkName: "component---src-pages-anazitisi-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-asfaleia-synallagwn-js": () => import("./../../../src/pages/asfaleia-synallagwn.js" /* webpackChunkName: "component---src-pages-asfaleia-synallagwn-js" */),
  "component---src-pages-checkout-jsx": () => import("./../../../src/pages/checkout.jsx" /* webpackChunkName: "component---src-pages-checkout-jsx" */),
  "component---src-pages-custom-creations-jsx": () => import("./../../../src/pages/custom-creations.jsx" /* webpackChunkName: "component---src-pages-custom-creations-jsx" */),
  "component---src-pages-epikoinonia-jsx": () => import("./../../../src/pages/epikoinonia.jsx" /* webpackChunkName: "component---src-pages-epikoinonia-jsx" */),
  "component---src-pages-etaireia-jsx": () => import("./../../../src/pages/etaireia.jsx" /* webpackChunkName: "component---src-pages-etaireia-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oroi-xrhshs-js": () => import("./../../../src/pages/oroi-xrhshs.js" /* webpackChunkName: "component---src-pages-oroi-xrhshs-js" */),
  "component---src-pages-payment-failure-jsx": () => import("./../../../src/pages/payment/failure.jsx" /* webpackChunkName: "component---src-pages-payment-failure-jsx" */),
  "component---src-pages-payment-success-jsx": () => import("./../../../src/pages/payment/success.jsx" /* webpackChunkName: "component---src-pages-payment-success-jsx" */),
  "component---src-pages-tropoi-apostolhs-js": () => import("./../../../src/pages/tropoi-apostolhs.js" /* webpackChunkName: "component---src-pages-tropoi-apostolhs-js" */),
  "component---src-pages-tropoi-plhromhs-js": () => import("./../../../src/pages/tropoi-plhromhs.js" /* webpackChunkName: "component---src-pages-tropoi-plhromhs-js" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

