import React, { useState, useEffect, useContext, useMemo } from 'react';

import CartStore from 'store/cart';

const CartContext = React.createContext({
  cart: {},
  totals: {},
  removeItem: () => {},
  adjustItem: () => {},
  emptyCart: () => {},
});

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [totals, setTotals] = useState({});

  useEffect(() => {
    const subscription = CartStore.state$.subscribe((state) => {
      setCart(state.cart);
      setTotals(state.totals);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const adjustItem = (id, quantity) => {
    CartStore.adjuctCartItem(id, quantity);
  };

  const removeItem = (item) => {
    CartStore.removeCartItem(item);
  };

  const value = useMemo(
    () => ({
      cart,
      totals,
      removeItem,
      adjustItem,
      emptyCart: CartStore.emptyCart,
    }),
    [cart, totals]
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCartContext = () => {
  return useContext(CartContext);
};
