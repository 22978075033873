import React from 'react';

import * as styles from './Ripple.module.scss';

const Ripple = () => {
  return (
    <div className={styles.container}>
      <div className={styles.ripple}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Ripple;
