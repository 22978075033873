import {
  defer,
  merge,
  Subject,
  BehaviorSubject,
  combineLatest,
  fromEvent,
} from 'rxjs';
import {
  scan,
  startWith,
  filter,
  map,
  tap,
  shareReplay,
  retryWhen,
  delay,
  take,
} from 'rxjs/operators';

import api from 'api/app';

/**
 * Main Observables
 *
 */
const isCash$ = new BehaviorSubject(false);
const cartAdd$ = new Subject();
const cartRemove$ = new Subject();
const cartAdjust$ = new Subject();
const cartEmpty$ = new Subject();

const INITIAL_DATA = [];

const getInitialCart = () => {
  try {
    return JSON.parse(localStorage.getItem('cart')).cart || INITIAL_DATA;
  } catch (e) {
    return INITIAL_DATA;
  }
};

const settings$ = defer(() => api.get('/settings')).pipe(
  map(({ data }) => data),
  retryWhen((errors) => errors.pipe(delay(3000), take(10))),
  shareReplay()
);

const storage$ =
  typeof window !== 'undefined'
    ? fromEvent(window, 'storage').pipe(
        delay(250),
        // listen to our storage key
        filter((evt) => evt.key === 'cart'),
        filter((evt) => evt.newValue !== null),
        // deserialize the stored actions
        // get the last stored action from the actions array
        map((evt) => JSON.parse(evt.newValue)),
        map((evt) => ({ ...evt, action: 'STORAGE' }))
      )
    : new Subject();

/**
 * Main application cart Observable
 * This could start with items from local storage or even an API call
 * We use scan peak at the items within the cart and add and remove
 */
const cart$ = merge(
  cartAdd$,
  cartRemove$,
  cartAdjust$,
  cartEmpty$,
  storage$
).pipe(
  startWith(getInitialCart()),
  scan((acc, item) => {
    switch (item.action) {
      case 'ADD':
        let temp = [];
        let exists = false;
        if (acc.length > 0) {
          temp = acc.map((i) => {
            if (i.id !== item.id) {
              return i;
            }
            exists = true;
            const { action, ...itemToStore } = item;
            return { ...i, ...itemToStore };
          });
        }
        if (!exists) {
          const { action, ...itemToStore } = item;
          temp = [...acc, itemToStore];
        }
        return temp;
      case 'ADJUST':
        return acc.map((i) => {
          if (i.id === item.id) {
            return { ...i, quantity: item.quantity };
          }
          return i;
        });
      case 'REMOVE':
        return [...acc.filter((i) => i.id !== item.id)];
      case 'CLEAR':
        return [];
      case 'STORAGE':
        return item.cart;
      default:
        return [];
    }
  })
);

/**
 * Calcs all Totals from being piped through the cart Observable
 * When an item gets added or removed it will automatically calc
 */
const totals$ = combineLatest([cart$, settings$, isCash$]).pipe(
  map(([products, settings, isCash]) => {
    let amount = 0;
    let quantity = 0;
    for (const i of products) {
      amount += i.price * i.quantity;
      quantity += i.quantity;
    }
    let sum = amount;
    const total = {
      amount: (amount / 100).toFixed(2),
      amountInt: amount,
      priceWithoutFee: (settings.orderProductsPriceWithoutFee / 100).toFixed(2),
      priceWithoutFeeInt: settings.orderProductsPriceWithoutFee,
      shippingFee: (settings.shippingFee / 100).toFixed(2),
      shippingFeeInt: settings.shippingFee,
      hasShippingFee: amount < settings.orderProductsPriceWithoutFee,
      cashShippingFee: (settings.cashShippingFee / 100).toFixed(2),
      cashShippingFeeInt: settings.cashShippingFee,
      hasCashShippingFee: !!isCash,
    };
    if (sum < settings.orderProductsPriceWithoutFee) {
      sum += settings.shippingFee;
    }
    if (isCash) {
      sum += settings.cashShippingFee;
    }
    total.sum = (sum / 100).toFixed(2);
    total.sumInt = sum;
    return { cart: products, totals: { ...total, quantity } };
  })
);

/**
 * Main Shopping Cart StateTree
 * Combines all dependencies and maps them to the StateTree Object
 */
const state$ = totals$.pipe(
  tap((cart) => localStorage.setItem('cart', JSON.stringify(cart))),
  shareReplay(1)
);

const CartStore = {
  state$,
  isCashSubscribe: (setState) => isCash$.subscribe(setState),
  // facade for next of cartAdd subject
  addCartItem(item, quantity) {
    cartAdd$.next({
      id: item.id || item._id,
      price: item.finalPrice,
      relativePath: item.relativePath,
      quantity,
      product: { ...item },
      action: 'ADD',
    });
  },
  // facade for next of cartRemove subject
  removeCartItem(item) {
    cartRemove$.next({ ...item, action: 'REMOVE' });
  },
  // facade for next of isCash behaviour subject
  setIsCash(state) {
    isCash$.next(state);
  },
  // facade for next of adjuctCartItem subject
  adjuctCartItem(id, quantity) {
    cartAdjust$.next({ id, quantity, action: 'ADJUST' });
  },
  // facade for next of cartEmpty subject
  emptyCart() {
    cartEmpty$.next({ action: 'CLEAR' });
  },
};

export default CartStore;
