import React from 'react';

import Ripple from 'components/Ripple/Ripple';

import { useAuthUser } from './AuthUser';

const Loader = ({ children }) => {
  const { loading } = useAuthUser();
  return (
    <>
      {loading && <Ripple />}
      {children}
    </>
  );
};

export default Loader;
