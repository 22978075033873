import { getItem, setItem, removeItem } from 'utilities/storage/storage';

const TOKEN_KEY = 'token';

export const getToken = () => {
  return getItem({ key: TOKEN_KEY });
};

export const setToken = ({ token }) => {
  return setItem({
    key: TOKEN_KEY,
    value: token,
    expiresInDays: 2 * 365,
  });
};

export const removeToken = () => {
  return removeItem({ key: TOKEN_KEY });
};

const authStorage = {
  getToken,
  setToken,
  removeToken,
};

export default authStorage;
